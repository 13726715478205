import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const lngs: Array<any> = [
    { id: 'es', name: 'ES' },
    { id: 'en', name: 'EN' }
]

export default function LanguageSelector() {
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.resolvedLanguage);

    console.log(selectedLanguage);
    return (
        <div>
            <FormControl fullWidth variant='standard' style={{marginLeft: 5, marginRight: 5}}>
                <Select
                    style={{
                        color: 'white'
                    }}
                    label={t('language')}
                    value={selectedLanguage}>
                    {lngs.map((lng) => (
                        <MenuItem key={lng.id}
                            value={lng.id}
                            onClick={() => {
                                i18n.changeLanguage(lng.id);
                                setSelectedLanguage(lng.id);
                            }}>
                            {lng.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>




        </div>
    );
}