import { Button, Modal, Box, Typography, TextField, Checkbox, FormControlLabel, CircularProgress } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import jobApplicationService from "../../../services/job-application-service";
import { useEffect, useState } from "react";

export interface AddOfferModalProps {
    setOpen: (value: boolean) => any;
    reloadData: () => any;
    open: boolean;
    jobOfferId: number;
    clientId: number
};

export interface AddJobOffer {
    position: string,
    clientId: number,
    description: string,
    isAvailable: boolean,
    salary: string,
    currency: string,
    jobOfferId: number,
}

export default function AddOfferModal(props: AddOfferModalProps) {
    const { open, setOpen, jobOfferId, clientId, reloadData } = props;
    const handleClose = () => setOpen(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<AddJobOffer>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isAvailable, setIsAvailable] = useState<boolean>(false);

    const handleForm: SubmitHandler<AddJobOffer> = (data) => {
        data.clientId = clientId;
        if (jobOfferId > 0) {
            data.jobOfferId = jobOfferId;
            jobApplicationService.updateJobOffer(data)
                .then(resp => {
                    reloadData();
                    handleClose();
                })
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            jobApplicationService.createJobOffer(data)
                .then(resp => {
                    reloadData();
                    handleClose();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const getJobOffer = () => {
        if (jobOfferId != undefined && jobOfferId > 0) {
            setLoading(true);
            jobApplicationService.getJobOfferById(jobOfferId)
                .then(response => {
                    setValue("position", response.data.position || "");
                    setValue("description", response.data.description || "");
                    setValue("currency", response.data.currency || "");
                    setValue("salary", response.data.salary || "");
                    setIsAvailable(response.data.isAvailable);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        getJobOffer();
    }, []);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" marginBottom={3} variant="h6" component="h2">
                        {jobOfferId == 0 ? "Agregar oferta de trabajo" : "Editar oferta de trabajo"}
                    </Typography>
                    {jobOfferId != undefined && jobOfferId > 0 && loading
                        ? <Box justifyContent={'center'} alignContent={'center'} sx={{ display: 'flex' }}>
                            <CircularProgress size={100} />
                        </Box> :
                        <form
                            onSubmit={handleSubmit(handleForm)}>
                            <TextField
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                label={'Position'}
                                {...register("position")}
                            />

                            <TextField
                                multiline
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                label={'Description'}
                                {...register("description")}
                            />

                            <TextField
                                multiline
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                label={'Currency'}
                                {...register("currency")}
                            />

                            <TextField
                                multiline
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                label={'Salary'}
                                {...register("salary")}
                            />

                            <FormControlLabel
                                control={<Checkbox {...register("isAvailable")}
                                    checked={isAvailable} // Sincroniza el estado local
                                    onChange={(e) => {
                                        setIsAvailable(e.target.checked);
                                        setValue("isAvailable", e.target.checked);
                                    }} />}
                                label="Available" />

                            <div style={{
                                textAlign: 'end'
                            }}>
                                <Button sx={{ marginTop: 2, marginRight: 1 }}
                                    variant="contained"
                                    color="error"
                                    type="button"
                                    onClick={handleClose}>Salir</Button>
                                <Button sx={{ marginTop: 2 }} variant="contained" type="submit">Guardar</Button>
                            </div>

                        </form>
                    }
                </Box>


            </Modal>
        </div>
    );
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    padding: 4,
};