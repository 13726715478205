import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import spanishLanguage from './translation/es';
import englishLanguage from './translation/en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: englishLanguage.translation
      },
      es: {
        translation: spanishLanguage.translation
      }
    }
  });

export default i18n;