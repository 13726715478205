import { Box, Typography } from "@mui/material";

export interface ClientOpenings {
    position: string;
    description: string;
    isAvailable: boolean;
}

export default function JobOffer() {
    return (
        <Box borderRadius={2}
            bgcolor={"Background"}
            boxShadow={1}
            sx={{ padding: 2, height: 'auto', marginBottom: 5 }}>
            <Typography variant="h4">Offers</Typography>
        </Box>
    )
}