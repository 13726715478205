import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: `"Montserrat", "sans-serif"`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700
  },
  palette: {
    primary: {
      main: '#062675', // azurl oscuro
    },
    secondary: {
      main: '#269d6d', // Verde
    },
    error: {
      main: '#d32f2f', // Rojo
    },
    warning: {
      main: '#ae754f', // Amarillo
    },
    info: {
      main: '#3f83e7', // Azul
    },
    success: {
      main: '#4caf50', // Verde
    },
    background: {
      default: '#f4f4f4', // Gris muy claro
      paper: '#ffffff', // Blanco
    },
    text: {
      primary: '#6d6d8f', // Gris Oscuro
      secondary: '#000000', // Negro
      
    }
  },
});

export default theme;