import { Box, Button, FormControl, FormControlLabel, MenuItem, Switch, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-number-input';
import { StepperContext, StepperContextModel } from "../../../contexts/request-stepper-context";
import { Country, State, City } from 'country-state-city';
import { useParams } from "react-router-dom";
import jobApplicationService from "../../../services/job-application-service";
import { educationalLevels, englishLevels, genderList, maritalStatusList, visaTypes } from "../../../models/shared-data";

export interface PersonalInfo {
    identification: string;
    name: string;
    middleName: string;
    lastName: string;
    gender: number;
    maritalStatus: number;
    partnerName: string;
    haveKids: boolean;
    educationLevel: string,
    profession: string;
    englishLevel: number;
    workExperience: string;
    havePassport: boolean;
    visa: string;
    country: string;
    state: string;
    city: string;
    address: string;
    phone: string;
    birthDate: Date;
    email: string;
    jobOfferId?: number;
}

export default function PersonalInfoForm() {
    const { t } = useTranslation();
    const { handleNext, setPersonalInfo, personalInfo } = useContext(StepperContext) as StepperContextModel;
    const { register, handleSubmit, formState: { errors } } = useForm<PersonalInfo>();
    const [maritalStatus, setMaritalStatus] = useState<number>(0);
    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const [selectedState, setSelectedState] = useState<string>('');
    let { jobOfferId } = useParams();

    const countries = Country.getAllCountries();
    const [states, setStates] = useState<Array<any>>([]);
    const [cities, setCities] = useState<Array<any>>([]);

    const [isIdValid, setIsIdValid] = useState<boolean>(true);

    useEffect(() => {
        setStates(State.getStatesOfCountry(selectedCountry));
    }, [selectedCountry]);

    useEffect(() => {
        setCities(City.getCitiesOfState(selectedCountry, selectedState));
    }, [selectedState]);

    const validateIdentification = (identification: string) => {
        jobApplicationService.validateApplicantId(identification)
            .then(response => {
                setIsIdValid(response.data);
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    const handleForm: SubmitHandler<PersonalInfo> = (data) => {
        data.jobOfferId = parseInt(jobOfferId ?? "0");
        setPersonalInfo(data);
        if(isIdValid){
            handleNext();
        }
    }

    return (
        <Box sx={{ padding: 2 }}>
            <form onSubmit={handleSubmit(handleForm)} style={{ marginTop: 20, marginBottom: 10 }}>
                <TextField
                    id="id"
                    label={t('forms.personalInfo.id')}
                    variant="outlined"
                    size="small"
                    defaultValue={personalInfo?.identification}
                    error={errors!.identification ? true : false}
                    {...register('identification', {
                        required: 'Id is required',
                        pattern: { value: /^[0-9]+$/, message: 'The Id must be number.' },
                        maxLength: { value: 13, message: 'Must have 13 characters' },
                        minLength: { value: 13, message: 'Must have 13 characters' }
                    })}
                    helperText={errors!.identification?.message!}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }}
                    onBlur={(e) => validateIdentification(e.target.value)} 
                />
                <Typography variant="caption" color="error" sx={{
                     display: isIdValid ? 'none' : 'block',
                     width: { xs: '100%', sm: '48%' }
                    }}>
                    {t('forms.personalInfo.idIsNotValid')}
                </Typography>

                <TextField
                    id="name"
                    label={t('forms.personalInfo.name')}
                    variant="outlined"
                    size="small"
                    defaultValue={personalInfo?.name}
                    {...register('name', { required: true })}
                    error={errors!.name ? true : false}
                    helperText={errors.name?.message!}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }} 
                />

                <TextField
                    id="middleName"
                    label={t('forms.personalInfo.middleName')}
                    variant="outlined"
                    size="small"
                    defaultValue={personalInfo?.middleName}
                    {...register('middleName')}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }} 
                />

                <TextField
                    id="lastName"
                    label={t('forms.personalInfo.lastName')}
                    variant="outlined"
                    size="small"
                    defaultValue={personalInfo?.lastName}
                    {...register('lastName', { required: 'Field is required' })}
                    helperText={errors.lastName?.message}
                    error={errors!.lastName ? true : false}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }} 
                />

                <TextField
                    id="birthDate"
                    label={t('forms.personalInfo.birthDate')}
                    defaultValue={personalInfo?.birthDate}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    type="date"
                    size="small"
                    {...register('birthDate', { required: true })}
                    error={errors!.birthDate ? true : false}
                    helperText={errors.birthDate?.message}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }} 
                />

                <TextField
                    id="gender"
                    label={t('forms.personalInfo.gender')}
                    defaultValue={personalInfo?.gender}
                    variant="outlined"
                    size="small"
                    select
                    {...register('gender', { required: 'Select an option' })}
                    error={errors!.gender ? true : false}
                    helperText={errors.gender?.message}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }}>
                    {genderList.map(gender => (
                        <MenuItem key={gender.value} value={gender.value}>
                            {t(gender.label)}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    id="maritalStatus"
                    label={t('forms.personalInfo.maritalStatus')}
                    defaultValue={personalInfo?.maritalStatus}
                    variant="outlined"
                    size="small"
                    select
                    {...register('maritalStatus', {
                        required: 'Select an option',
                        onChange: (event) => setMaritalStatus(event.target.value)
                    })}
                    error={errors!.maritalStatus ? true : false}
                    helperText={errors.maritalStatus?.message}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }}>
                    {maritalStatusList.map(maritalStatus => (
                        <MenuItem key={maritalStatus.value} value={maritalStatus.value}>
                            {t(maritalStatus.label)}
                        </MenuItem>
                    ))}
                </TextField>

                {maritalStatus == 2 && (
                    <TextField
                        id="partner"
                        label={t('forms.personalInfo.partnerName')}
                        defaultValue={personalInfo?.partnerName}
                        variant="outlined"
                        size="small"
                        {...register('partnerName')}
                        sx={{
                            width: { xs: '100%', sm: '48%' },
                            marginTop: 2,
                            marginRight: { sm: 2, xs: 0 }
                        }} 
                    />
                )}

                <FormControl sx={{ marginTop: 2 }}>
                    <FormControlLabel {...register('haveKids')} control={<Switch defaultChecked={personalInfo?.haveKids} />} label={t('forms.personalInfo.haveKids')} />
                </FormControl>

                <TextField
                    id="educationLevel"
                    label={t('forms.personalInfo.educationLevel')}
                    variant="outlined"
                    size="small"
                    defaultValue={personalInfo?.educationLevel}
                    select
                    {...register('educationLevel', { required: true })}
                    error={errors!.educationLevel ? true : false}
                    helperText={errors.educationLevel?.message}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }}>
                    {educationalLevels.map(level => (
                        <MenuItem key={level.value} value={level.value}>
                            {t(level.label)}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    id="profession"
                    label={t('forms.personalInfo.profession')}
                    defaultValue={personalInfo?.profession}
                    variant="outlined"
                    size="small"
                    {...register('profession', { required: true })}
                    error={errors!.profession ? true : false}
                    helperText={errors.profession?.message}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }} 
                />

                <TextField
                    id="englishLevel"
                    label={t('forms.personalInfo.englishLevel')}
                    variant="outlined"
                    size="small"
                    defaultValue={personalInfo?.englishLevel}
                    select
                    {...register('englishLevel', { required: 'Select an option' })}
                    error={errors!.englishLevel ? true : false}
                    helperText={errors.englishLevel?.message}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }}>
                    {englishLevels.map(level => (
                        <MenuItem key={level.value} value={level.value}>
                            {t(level.label)}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    id="workExperience"
                    label={t('forms.personalInfo.workExperience')}
                    defaultValue={personalInfo?.workExperience}
                    variant="outlined"
                    size="small"
                    {...register('workExperience', { required: true })}
                    error={errors!.workExperience ? true : false}
                    helperText={errors.workExperience?.message}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }} 
                />

                <FormControl sx={{ marginTop: 2 }}>
                    <FormControlLabel {...register('havePassport')} control={<Switch defaultChecked={personalInfo?.havePassport} />} label={t('forms.personalInfo.havePassport')} />
                </FormControl>

                <TextField
                    id="visa"
                    label={t('forms.personalInfo.visa')}
                    variant="outlined"
                    size="small"
                    defaultValue={personalInfo?.visa}
                    select
                    {...register('visa')}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }}>
                    {visaTypes.map(visa => (
                        <MenuItem key={visa.value} value={t(visa.label)}>
                            {t(visa.label)}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    id="country"
                    label={t('forms.personalInfo.country')}
                    variant="outlined"
                    size="small"
                    select
                    {...register('country', {
                        required: 'Select a country',
                        onChange: (event) => setSelectedCountry(event.target.value)
                    })}
                    defaultValue={personalInfo?.country}
                    error={errors!.country ? true : false}
                    helperText={errors.country?.message}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }}>
                    {countries.map((country, index) => (
                        <MenuItem key={country.isoCode + index} value={country.isoCode}>
                            {country.name}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    id="state"
                    label={t('forms.personalInfo.state')}
                    variant="outlined"
                    size="small"
                    select
                    {...register('state', {
                        required: 'Select a state',
                        onChange: (event) => setSelectedState(event.target.value)
                    })}
                    defaultValue={personalInfo?.state}
                    error={errors!.state ? true : false}
                    helperText={errors.state?.message}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }}>
                    {states.map((state, index) => (
                        <MenuItem key={state.isoCode + index} value={state.isoCode}>
                            {state.name}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    id="city"
                    label={t('forms.personalInfo.city')}
                    variant="outlined"
                    size="small"
                    select
                    {...register('city', { required: true })}
                    defaultValue={personalInfo?.city}
                    error={errors!.city ? true : false}
                    helperText={errors.city?.message}
                    sx={{
                        width: { xs: '100%', sm: '48%' },
                        marginTop: 2,
                        marginRight: { sm: 2, xs: 0 }
                    }}>
                    {cities.map((city, index) => (
                        <MenuItem key={city.name + index} value={city.name}>
                            {city.name}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    id="address"
                    label={t('forms.personalInfo.address')}
                    defaultValue={personalInfo?.address}
                    variant="outlined"
                    size="small"
                    {...register('address', { required: true })}
                    error={errors!.address ? true : false}
                    helperText={errors.address?.message}
                    sx={{
                        width: '100%',
                        marginTop: 2
                    }} 
                />

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 2
                }}>
                    <Typography variant="caption">{t('forms.personalInfo.phone')}</Typography>
                    <PhoneInput
                        id="phone"
                        international
                        countryCallingCodeEditable={false}
                        {...register('phone', { required: true })}
                        defaultValue={personalInfo?.phone}
                        defaultCountry={selectedCountry as any}
                        onChange={(value) => register('phone').onChange({ target: { value } })}
                        style={{
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                            borderRadius: 4,
                            padding: 10,
                            fontSize: 16,
                            marginBottom: errors.phone ? 10 : 0,
                        }}
                    />
                    {errors.phone && (
                        <Typography variant="caption" color="error">
                            {'Field required'}
                        </Typography>
                    )}
                </Box>

                <TextField
                    id="email"
                    label={t('forms.personalInfo.email')}
                    variant="outlined"
                    size="small"
                    defaultValue={personalInfo?.email}
                    {...register('email', { required: true })}
                    error={errors!.email ? true : false}
                    helperText={errors.email?.message}
                    sx={{
                        width: '100%',
                        marginTop: 2
                    }} 
                />

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 4
                }}>
                    <Button variant="contained" type="submit">
                        {t('forms.personalInfo.next')}
                    </Button>
                </Box>
            </form>
        </Box>
    )
}
