import { createContext, useState } from "react";
import PersonalInfoForm, { PersonalInfo } from "../components/openings/request-steps/personal-info-form";
import { useTranslation } from "react-i18next";
import DSForm, { DSFormRequest } from "../components/openings/request-steps/ds-form";

export interface StepperContextModel {
    activeStep: number;
    steps: Array<StepModel>;
    personalInfo: PersonalInfo | undefined;
    setPersonalInfo: (personalInfo: PersonalInfo) => any;
    dsForm: DSFormRequest | undefined;
    setDSForm: (form: DSFormRequest) => any;
    setActiveStep: (value: number) => any;
    handleNext: () => any;
    handleBack: () => any;
    handleReset: () => any;
}

interface StepModel {
    title: string;
    element: any;
}

export const StepperContext = createContext<StepperContextModel | any>(null);

export default function InitializeStepperContext(): StepperContextModel {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [personalInfo, setPersonalInfo] = useState<PersonalInfo>();
    const [dsForm, setDSForm] = useState<DSFormRequest>();
    const steps: Array<StepModel> = [
        { title: t("steps.personalInfo"), element: <PersonalInfoForm /> },
        { title: t("steps.dsForm"), element: <DSForm /> },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    return {
        activeStep,
        setActiveStep,
        handleNext,
        handleBack,
        handleReset,
        steps,
        personalInfo,
        dsForm,
        setPersonalInfo,
        setDSForm
    };
}