import axios from "axios"
import { RequestParams } from "../../pages/dashboard-pages/admin/requests";
import { AddJobOffer } from "../components/dashboard/admin/add-offer-modal";

function createJobApplication(request: any) {
    return axios.post("JobApplication", request);
}

function getJobApplicationStatuses() {
    return axios.get("JobApplication/jobApplicationStatuses");
}

function updateJobApplicationStatus(id: number, statusId: number, reviewerId: number) {
    return axios.put(`JobApplication/${id}?statusId=${statusId}&reviewerId=${reviewerId}`);
}

function getJobOffers() {
    return axios.get("JobOffer");
}

function createJobOffer(body: AddJobOffer) {
    return axios.post("JobOffer", body);
}

function updateJobOffer(body: AddJobOffer) {
    return axios.put("JobOffer", body);
}

function getJobOfferById(id: number) {
    return axios.get(`JobOffer/${id}`);
}

function getClientJobOffers(clientId: number, pageSize: number, pageIndex: number) {
    return axios.get(`JobOffer/Client/${clientId}?pageSize=${pageSize}&pageIndex=${pageIndex}`);
}


function validateApplicantId(identification: string) {
    return axios.post(`JobApplication/validateId?applicantId=${identification}`);
}

function getJobApplications(requestParams: RequestParams) {
    let queryString: string = "";

    for (let key in requestParams) {
        let value = requestParams[key as keyof RequestParams];
        if (value != undefined && value !== "") {
            queryString = `${queryString}&${key}=${value}`
        }
    }

    return axios.get(`JobApplication?${queryString}`);
}

function downloadExcel(requestParams: RequestParams) {
    let queryString: string = "";

    for (let key in requestParams) {
        let value = requestParams[key as keyof RequestParams];
        if (value != undefined && value !== "") {
            queryString = `${queryString}&${key}=${value}`
        }
    }

    return axios.get(`JobApplication/downloadExcel?${queryString}`, {
        responseType: 'blob'
    });
}

function getJobApplicationById(id: number) {
    return axios.get(`JobApplication/${id}`);
}

export default {
    createJobApplication,
    getJobOffers,
    createJobOffer,
    updateJobOffer,
    getJobOfferById,
    validateApplicantId,
    getJobApplications,
    getJobApplicationById,
    downloadExcel,
    getClientJobOffers,
    getJobApplicationStatuses,
    updateJobApplicationStatus
}