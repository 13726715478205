import { Label } from "@mui/icons-material";

export const genderList = [
    { value: 1, label: 'forms.genderOptions.male' },
    { value: 2, label: 'forms.genderOptions.female' },
    { value: 3, label: 'forms.genderOptions.other' }];

export const maritalStatusList = [
    { value: 1, label: 'forms.maritalStatusOptions.single' },
    { value: 2, label: 'forms.maritalStatusOptions.married' },
    { value: 3, label: 'forms.maritalStatusOptions.divorced' },
    { value: 4, label: 'forms.maritalStatusOptions.widowed' },
    { value: 5, label: 'forms.maritalStatusOptions.separated' }
];
export const educationalLevels = [
    { value: 1, label: 'forms.educationalLevels.noFormalEducation' },
    { value: 2, label: 'forms.educationalLevels.primaryEducation' },
    { value: 3, label: 'forms.educationalLevels.secondaryEducation' },
    { value: 4, label: 'forms.educationalLevels.vocationalQualification' },
    { value: 5, label: 'forms.educationalLevels.bachelorDegree' },
    { value: 6, label: 'forms.educationalLevels.masterDegree' },
    { value: 7, label: 'forms.educationalLevels.doctorate' }
];

export const englishLevels = [
    { value: 1, label: 'forms.englishLevels.basic' },
    { value: 2, label: 'forms.englishLevels.intermediate' },
    { value: 3, label: 'forms.englishLevels.advanced' },
    { value: 4, label: 'forms.englishLevels.fluent' }
];

export const visaTypes = [
    { value: 0, label: 'forms.visaTypes.No' },
    { value: 1, label: 'forms.visaTypes.B2' },
    { value: 2, label: 'forms.visaTypes.B1' },
    { value: 3, label: 'forms.visaTypes.H1B' },
    { value: 4, label: 'forms.visaTypes.F1' },
    { value: 5, label: 'forms.visaTypes.J1' },
    { value: 6, label: 'forms.visaTypes.K1' },
    { value: 7, label: 'forms.visaTypes.CR1' },
    { value: 8, label: 'forms.visaTypes.E2' },
    { value: 9, label: 'forms.visaTypes.O1' },
    { value: 10, label: 'forms.visaTypes.R1' },
]