import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import NavBar from "../shared/components/nav-bar";
import { Outlet } from "react-router-dom";

export default function OpeningPage() {
    const { t } = useTranslation();
    return (
        <NavBar showLinks={false}>
            <Box textAlign={"center"}
                sx={{ marginTop: 10}}>
                <Container>
                    <Box
                        borderRadius={2}
                        bgcolor={"Background"}
                        boxShadow={1}
                        sx={{padding: 2, height: 'auto', marginBottom: 5}}>
                        
                        <Outlet/>
                    </Box>
                </Container>
            </Box>
        </NavBar>

    );
}