import { Box, Button, CircularProgress, Grid, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import jobApplicationService from "../../../shared/services/job-application-service";
import theme from "../../../theme";
import AddIcon from '@mui/icons-material/Add';
import AddOfferModal from "../../../shared/components/dashboard/admin/add-offer-modal";
import EditIcon from '@mui/icons-material/Edit';

export default function JobOffers() {
    const { clientId, name } = useParams();
    const [pageSize, setPageSize] = useState(5);
    const [pageIndex, setPageIndex] = useState(0);
    const [previousPageIndex, setPreviousPageIndex] = useState<Array<number>>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedJobOffer, setSelectedJobOffer] = useState<number>(0);

    const getData = () => {
        setLoading(true);
        jobApplicationService.getClientJobOffers(parseInt(clientId!), pageSize, pageIndex)
            .then(response => {
                setData(response.data.data);
                setTotalCount(response.data.totalCount);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [pageSize, pageIndex]);

    const nextPage = () => {
        setPreviousPageIndex([...previousPageIndex, pageIndex]);
        if (data.length > 0) {
            setPageIndex(data[data.length - 1].id);
        }
    }

    const previousPage = () => {
        const newPreviousPageIndex = [...previousPageIndex];
        let index = newPreviousPageIndex.pop();
        setPreviousPageIndex(newPreviousPageIndex);
        setPageIndex(index ?? 0);
    }

    return (<>
        <Grid container>
            <Grid item sm={10}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                    Administración de ofertas de trabajo de {name}
                </Typography>
            </Grid>
            <Grid item sm={2} sx={{ mb: 1 }}>
                <Button variant="contained" color="primary"
                    onClick={() => {
                        setSelectedJobOffer(0);
                        setOpenModal(true) 
                    }}
                    sx={{ alignContent: 'end' }}>
                    <AddIcon />
                    Agregar Oferta
                </Button>
            </Grid>
        </Grid>

        <TableContainer component={Paper}>
            <Box sx={{
                backgroundColor: theme.palette.background.paper,
                paddingX: 2,
                paddingY: 1
            }}>

            </Box>
            {loading ?
                <Box justifyContent={'center'} alignContent={'center'} sx={{ display: 'flex' }}>
                    <CircularProgress size={100} />
                </Box> :

                <Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead sx={{
                            backgroundColor: theme.palette.background.default,
                        }}>
                            <TableRow>
                                <TableCell>Position</TableCell>
                                <TableCell>Is Available</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Options</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <TableRow
                                    key={row.id}
                                    hover>
                                    <TableCell>
                                        {row.position}
                                    </TableCell>
                                    <TableCell>{row.isAvailable ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => {
                                                setSelectedJobOffer(row.id);
                                                setOpenModal(true);
                                            }}
                                            title="Open Details"
                                            aria-label="Open Details"
                                            color="primary">
                                            <EditIcon></EditIcon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            }
            <Grid
                container
                sx={{
                    padding: theme.spacing(2),
                }}>
                <Grid item md={6}>
                    <Typography fontWeight={"bold"} fontSize={14} variant="subtitle1" margin={2}>Total Requests: {totalCount}</Typography>
                </Grid>
                <Grid item md={6} sx={{
                    display: 'flex',
                    justifyContent: 'end',
                }}>
                    <span style={{
                        margin: 5,
                        padding: 10,
                    }}>Page Size:</span>
                    <Select
                        variant="standard"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={pageSize}
                        label="Page Size"
                        onChange={(e) => setPageSize(e.target.value as number)}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                    <Button sx={{ marginLeft: 2 }} onClick={previousPage}>Previous</Button>
                    <Button sx={{ marginLeft: 2 }} onClick={nextPage}>Next</Button>
                </Grid>
            </Grid>
        </TableContainer>
        {openModal && <AddOfferModal
            {...{
                open: openModal,
                setOpen: setOpenModal,
                clientId: parseInt(clientId!),
                reloadData: getData,
                jobOfferId: selectedJobOffer
            }} />}

    </>)
}