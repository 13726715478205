import { Box, Button, Grid, IconButton, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import theme from "../../../theme";
import React, { useContext } from "react";
import InitializeStepperContext, { StepperContext, StepperContextModel } from "../../contexts/request-stepper-context";

export default function OpeningRequestStepper() {
    const { t } = useTranslation();

    return (
        <StepperContext.Provider value={InitializeStepperContext()}>
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={2} sx={{ textAlign: 'start' }}>

                        <Link to={'/openings'} style={{
                            textDecoration: 'none',
                            color: theme.palette.primary.main
                        }}>
                            <Typography id="modal-modal-title" variant="body1">
                                {t('nav.openings')}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>

                <Box sx={{ width: '100%', marginTop: 5 }}>
                    <StepperContainer />
                </Box>
            </Box>
        </StepperContext.Provider>
    );
}

function StepperContainer() {
    const { activeStep, steps } = useContext(StepperContext) as StepperContextModel;

    return (
        <>
            <Stepper activeStep={activeStep}>
                {steps.map((step) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={step.title} {...stepProps}>
                            <StepLabel {...labelProps}>{step.title}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <Box textAlign={'start'}>
                {activeStep < steps.length ? steps[activeStep].element : null}
            </Box>

        </>
    );
}