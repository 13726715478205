import { Modal, Box, Typography, Divider, Grid, IconButton, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface OpeningDetailsModalProps {
    open: boolean;
    details?: any
    setOpen: (value: boolean) => any;
}

export default function OpeningDetailsModal(props: OpeningDetailsModalProps) {
    const { open, setOpen, details } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    }

    const applyHandle = () => {
        navigate(`request/${details.id!}`);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Grid container spacing={1}>
                    <Grid item xs={10}>
                        <Typography fontWeight={"bold"} id="modal-modal-title" variant="h6" component="h2">
                            {details?.position!}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}
                        sx={{
                            textAlign: 'end',
                        }}>
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: 15,
                                top: 10
                            }}
                            color="error"
                            onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Divider />
                <ul>
                    <li>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <b>{t("openings.details.description")}: </b>{details?.description!}
                        </Typography>
                    </li>
                    <li>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <b>{t("openings.details.location")}: </b>  {details?.location!}
                        </Typography>
                    </li>
                    <li>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <b>{t("openings.details.salary")}: </b> {details?.salary!} - {details.currency!}
                        </Typography>
                    </li>
                </ul>
                <Box
                    sx={{
                        position: "absolute",
                        textAlign: "center",
                        width: "100%",
                        bottom: "15px",
                        left: 0
                    }} >
                    <Button
                        onClick={applyHandle}
                        variant="contained">{t("openings.details.apply")}
                    </Button>
                </Box>

            </Box>
        </Modal>
    );
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80vh',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 2,
    borderRadius: 4,
    p: 4,
};