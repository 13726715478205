import { Box, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { StepperContext, StepperContextModel } from "../../../contexts/request-stepper-context";
import { useTranslation } from "react-i18next";
import jobApplicationService from "../../../services/job-application-service";
import { useNavigate } from "react-router-dom";

export interface DSFormRequest {
    haveDirectRelativeInUS: boolean;
    relatives: string;
    deniedVisa: boolean;
    canceledVisa: boolean;
    citizenRequest: boolean;
    outOfCountryLastFiveYears: boolean;
    visitedCountries: string;
    haveBeenOut: boolean;
    triedEnterIllegallyToUs: boolean;
}

export default function DSForm() {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors } } = useForm<DSFormRequest>();
    const { handleBack, setDSForm, personalInfo, dsForm } = useContext(StepperContext) as StepperContextModel;
    const [haveDirectRelativeInUS, setHaveDirectRelativeInUS] = useState<boolean>(false);
    const [outOfTheCountryLastFiveYears, setOutOfTheCountryLastFiveYears] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleForm: SubmitHandler<DSFormRequest> = (data) => {
        //setDSForm(data);
        jobApplicationService.createJobApplication({
            ...personalInfo,
            dSForm: data
        })
        .then(resp => {
            navigate("/confirmation");
            console.log(resp);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    return (
        <Box>
            <form style={{
                marginTop: 20,
                marginBottom: 10
            }} onSubmit={handleSubmit(handleForm)}>

                <FormControlLabel
                    control={<Checkbox {...register('haveDirectRelativeInUS', {
                        onChange: (value) => {
                            setHaveDirectRelativeInUS(value.target.checked);
                        }
                    })} />}
                    label={t("forms.dsForm.haveDirectRelativeInUS")}
                />

                {haveDirectRelativeInUS &&
                    <TextField
                        {...register('relatives', {
                            required: { value: haveDirectRelativeInUS, message: "The field is required" }
                        })}
                        label={t("forms.dsForm.relatives")}
                        variant="outlined"
                        fullWidth
                        error={errors.relatives ? true : false}
                        helperText={errors!.relatives?.message!}
                        margin="normal"
                    />}

                <FormControlLabel
                    control={<Checkbox {...register('deniedVisa')} />}
                    label={t("forms.dsForm.deniedVisa")}
                />
                <FormControlLabel
                    control={<Checkbox {...register('canceledVisa')} />}
                    label={t("forms.dsForm.canceledVisa")}
                />
                <FormControlLabel
                    control={<Checkbox {...register('citizenRequest')} />}
                    label={t("forms.dsForm.citizenRequest")}
                />
                <FormControlLabel
                    control={<Checkbox {...register('outOfCountryLastFiveYears', {
                        onChange: (value) => {
                            setOutOfTheCountryLastFiveYears(value.target.checked);
                        }
                    })} />}
                    label={t("forms.dsForm.outOfCountryLastFiveYears")}
                />

                {outOfTheCountryLastFiveYears &&
                    <TextField
                        {...register('visitedCountries', 
                            {
                                required: { value: outOfTheCountryLastFiveYears, message: "The field is required" }
                            }
                        )}
                        error={errors?.visitedCountries ? true : false}
                        helperText={ errors.visitedCountries?.message}
                        label={t("forms.dsForm.visitedCountries")}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                }

                <FormControlLabel
                    control={<Checkbox {...register('haveBeenOut')} />}
                    label={t("forms.dsForm.haveBeenOut")}
                />
                <FormControlLabel
                    control={<Checkbox {...register('triedEnterIllegallyToUs')} />}
                    label={t("forms.dsForm.triedEnterIllegallyToUs")}
                />


                <Box sx={{ paddingTop: 10, paddingRight: 10 }} textAlign={'end'}>
                    <Button sx={{ margin: 2 }} variant="contained" color="info" onClick={handleBack} type="button" title="Back">Back</Button>
                    <Button variant="contained" type="submit" title="Send Request">Send</Button>
                </Box>
            </form>
        </Box>
    );
}