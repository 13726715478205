import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, Grid, TextField, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import theme from "../../../theme";
import { useEffect, useState } from "react";
import jobApplicationService from "../../../shared/services/job-application-service";
import { educationalLevels, englishLevels, genderList, maritalStatusList } from "../../../shared/models/shared-data";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from "dayjs";
import { City, Country, State } from "country-state-city";

export default function RequestDetails() {
    const { t } = useTranslation();
    const { id } = useParams();
    const [jobApplication, setJobApplication] = useState<any>(undefined);

    const getJobApplication = () => {
        jobApplicationService.getJobApplicationById(parseInt(id!))
            .then(resp => {
                setJobApplication(resp.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getJobApplication();
    }, []);

    if (jobApplication === undefined) {
        return (
            <Box justifyContent={'center'} alignContent={'center'} sx={{ display: 'flex' }}>
                <CircularProgress size={100} />
            </Box>
        );
    }

    const calculateAge = (birthDate: any) => {
        const today = dayjs();
        const birth = dayjs(birthDate);
        let age = today.diff(birth, 'year');

        const currentYearBirthday = birth.add(age, 'year');
        if (today.isBefore(currentYearBirthday)) {
            age -= 1;
        }

        return age;
    }

    return (
        <>
            <Box sx={{
                backgroundColor: theme.palette.background.paper,
                padding: theme.spacing(1),
            }}>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography fontWeight={'bold'} variant="h6">Información personal:</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Nombre: </span>{jobApplication.applicant!.name!} {jobApplication.applicant!.middleName!} {jobApplication.applicant!.lastName!}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Id: </span>{jobApplication.applicant!.identification!}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Fecha de nacimiento: </span>{dayjs(jobApplication.applicant!.birthDate!).format('DD/MM/YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Edad: </span>{calculateAge(jobApplication.applicant!.birthDate!)}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Genero: </span>{t(genderList.find(s => s.value === jobApplication.applicant!.gender!)?.label!)}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Estado civil: </span>{t(maritalStatusList.find(s => s.value === jobApplication.applicant!.maritalStatus!)?.label!)}
                                </Typography>
                            </Grid>
                            {jobApplication.applicant!.maritalStatus! === 2 && (
                                <Grid item xs={3}>
                                    <Typography>
                                        <span style={{ fontWeight: 'bold' }}>Nombre de pareja: </span>{jobApplication.applicant!.partnerName!}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Tiene hijos: </span>{jobApplication.applicant!.haveKids! ? 'Si' : 'No'}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Nivel de educación: </span>{t(educationalLevels.find(s => s.value === jobApplication.applicant!.educationLevel!)?.label!)}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Profesión: </span>{jobApplication.applicant!.profession!}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Nivel de ingles: </span>{t(englishLevels.find(s => s.value === jobApplication.applicant!.englishLevel!)?.label!)}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Tiene Pasaporte: </span>{jobApplication.applicant!.havePassport! ? 'Si' : 'No'}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Visa: </span>{jobApplication.applicant!.visa!}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Experiencia: </span>{jobApplication.applicant!.workExperience!}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionDetails>

                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography fontWeight={'bold'} variant="h6">Información de contacto y dirección:</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Email: </span>{jobApplication.applicant!.email!}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Tel: </span>{jobApplication.applicant!.phone!}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>País: </span>{Country.getCountryByCode(jobApplication.applicant!.country!)?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Estado: </span>{State.getStateByCodeAndCountry(jobApplication.applicant!.state!, jobApplication.applicant!.country!)?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Ciudad: </span>{jobApplication.applicant!.city!}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Dirección: </span>{jobApplication.applicant!.address!}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography fontWeight={'bold'} variant="h6">DS-160:</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Tiene familiares en US: </span>{jobApplication.applicant!.applicantDSForm!.haveDirectRelativeInUS! ? 'Si' : 'No'}
                                </Typography>
                            </Grid>
                            {jobApplication.applicant!.applicantDSForm!.haveDirectRelativeInUS! &&
                                (<Grid item xs={3}>
                                    <Typography>
                                        <span style={{ fontWeight: 'bold' }}>Familiares: </span>{jobApplication.applicant!.applicantDSForm!.relatives}
                                    </Typography>
                                </Grid>)}
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Le han denegado visa: </span>{jobApplication.applicant!.applicantDSForm!.deniedVisa! ? 'Si' : 'No'}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Le han cancelado visa: </span>{jobApplication.applicant!.applicantDSForm!.canceledVisa! ? 'Si' : 'No'}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Ha solicitado ciudadanía: </span>{jobApplication.applicant!.applicantDSForm!.citizenRequest! ? 'Si' : 'No'}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Ha salido del país en los ultimos 5 años: </span>{jobApplication.applicant!.applicantDSForm!.outOfCountryLastFiveYears! ? 'Si' : 'No'}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Ha intentado entrar a US de manera ilegal: </span>{jobApplication.applicant!.applicantDSForm!.triedEnterIllegallyToUs! ? 'Si' : 'No'}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Ha salido del país </span>{jobApplication.applicant!.applicantDSForm!.haveBeenOut! ? 'Si' : 'No'}
                                </Typography>
                            </Grid>
                            {jobApplication.applicant!.applicantDSForm!.haveBeenOut! &&
                                (<Grid item xs={3}>
                                    <Typography>
                                        <span style={{ fontWeight: 'bold' }}>Familiares: </span>{jobApplication.applicant!.applicantDSForm!.visitedCountries}
                                    </Typography>
                                </Grid>)}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography fontWeight={'bold'} variant="h6">Posibles familiares:</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                        {jobApplication.possibleRelatives && (
                            jobApplication.possibleRelatives.map((s: any) => {
                                return (<li>{s.identification} - {s.name} {s.lastName}</li>);
                            })
                        )}
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </Box>

        </>
    );
}

// {
//     "id": 2,
//         "jobOfferId": 1,
//             "created": "2024-06-13T02:11:39.147044Z",
//                 "updated": "2024-06-13T02:11:39.147057Z",
//                     "jobOffer": {
//         "id": 1,
//             "clientId": 1,
//                 "position": "FullStack Developer",
//                     "description": "Remote position",
//                         "isAvailable": true,
//                             "jobApplications": [
//                                 null
//                             ],
//                                 "client": {
//             "id": 1,
//                 "userId": "3494a2b9-dd4d-4dd5-ba88-504b6ace1664",
//                     "organizationName": "Dev Test",
//                         "phoneNumber": "+1 234 234 2323",
//                             "country": "US",
//                                 "state": "FL",
//                                     "city": "Miami",
//                                         "address": "miami beach",
//                                             "email": "eduardovaldez985@gmail.com",
//                                                 "role": "client",
//                                                     "jobOffers": [
//                                                         null
//                                                     ]
//         }
//     },
