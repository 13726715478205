import { Box, Button, Input, MenuItem, TextField, Typography } from "@mui/material";
import theme from "../../../theme";
import { useTranslation } from "react-i18next";
import Client from "../../../shared/models/client";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { SubmitHandler, useForm } from "react-hook-form";
import clientService from "../../../shared/services/client-service";
import { useEffect, useState } from "react";
import { City, Country, State } from "country-state-city";


export interface ClientRequest {
    organizationName: string;
    phoneNumber: string;
    country: string;
    state: string;
    city: string;
    address: string;
}

export default function SetupPage(client: Client | undefined) {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors } } = useForm<any>();

    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const [selectedState, setSelectedState] = useState<string>('');
    const countries = Country.getAllCountries();
    const [states, setStates] = useState<Array<any>>([]);
    const [cities, setCities] = useState<Array<any>>([]);

    useEffect(() => {
        setStates(State.getStatesOfCountry(selectedCountry));
    }, [selectedCountry]);

    useEffect(() => {
        setCities(City.getCitiesOfState(selectedCountry, selectedState));
    }, [selectedState]);

    const handleForm: SubmitHandler<any> = (data: any) => {
        console.log('data', data);
        const request = {
            userId: client?.userId,
            organizationName: data.organizationName,
            phoneNumber: data.phoneNumber,
            country: data.country,
            state: data.state,
            city: data.city,
            address: data.address
        };

        clientService.updateClient(request)
            .then(resp => {
                if (resp.data) {
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <Box sx={{
            backgroundColor: theme.palette.background.paper,
            padding: 5,
        }}>
            <Typography variant="h5">
                {t('client.setUpTitle')}
            </Typography>

            <form style={{
                marginTop: 10,
                paddingTop: 20,
                paddingBottom: 20
            }} onSubmit={handleSubmit(handleForm)}>
                <TextField
                    label={t('client.email')}
                    sx={{ width: '90%', margin: 2 }}
                    value={client?.email}
                    disabled />

                <TextField
                    label={t('client.organizationName')}
                    sx={{ width: '90%', margin: 2 }}
                    {...register('organizationName', { required: true })}
                    error={errors!.organizationName ? true : false}
                    value={client?.organizationName} />

                <TextField
                    id="country"
                    label={t('forms.personalInfo.country')}
                    // defaultValue={personalInfo?.country}
                    variant="outlined"
                    select
                    {...register('country',
                        {
                            required: true,
                            onChange: (value) => { setSelectedCountry(value.target.value) }
                        })}
                    sx={{
                        minWidth: 260,
                        margin: 2
                    }}>
                    {countries.map(country => {
                        return (<MenuItem key={country.isoCode} value={country.isoCode}>{country.name}</MenuItem>);
                    })}
                </TextField>

                <TextField
                    id="state"
                    label={t('forms.personalInfo.state')}
                    //  defaultValue={personalInfo?.state}
                    variant="outlined"
                    select
                    {...register('state',
                        {
                            required: true,
                            onChange: (value) => { setSelectedState(value.target.value) }
                        })}
                    sx={{
                        minWidth: 260,
                        margin: 2
                    }}>
                    {states.map(state => {
                        return (<MenuItem key={state.isoCode} value={state.isoCode}>{state.name}</MenuItem>);
                    })}
                </TextField>

                <TextField
                    id="city"
                    label={t('forms.personalInfo.city')}
                    // defaultValue={personalInfo?.city}
                    variant="outlined"
                    select
                    {...register('city',
                        {
                            required: true,
                        })}
                    sx={{
                        minWidth: 260,
                        margin: 2
                    }}>
                    {cities.map(city => {
                        return (<MenuItem key={city.isoCode} value={city.name}>{city.name}</MenuItem>);
                    })}
                </TextField>

                <TextField
                    multiline
                    maxRows={2}
                    id="address"
                    label={t('forms.personalInfo.address')}
                    defaultValue={client?.address}
                    variant="outlined"
                    {...register('address', { required: true })}
                    sx={{
                        margin: 2,
                        minWidth: '90%'
                    }} />

                <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    style={{
                        width: '30%',
                        marginTop: 10,
                        marginLeft: 20,
                    }}
                    numberInputProps={{
                        style: {
                            height: 40,
                            fontSize: 'medium'
                        }
                    }}
                    {...register('phoneNumber', {
                        required: true,
                        minLength: { value: 6, message: "Phone number is required." }
                    })}
                    error={errors!.phoneNumber ? true : false}
                    defaultCountry={selectedCountry as any}
                    placeholder={t('client.phoneNumber')}
                    onChange={() => console.log()} />
                {errors!.phoneNumber?.message
                    ? <Typography color={'error'}>{errors!.phoneNumber?.message.toString()}</Typography>
                    : null}
                <br></br>

                <Button
                    sx={{ margin: 2 }}
                    type="submit" variant="contained">Start</Button>
            </form>
        </Box >
    );
}