import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Button, Grid, FormControl, InputLabel, Select, MenuItem, Typography, CircularProgress, TextField, Autocomplete, IconButton } from "@mui/material";
import theme from "../../../theme";
import { useEffect, useState } from "react";
import jobApplicationService from "../../../shared/services/job-application-service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
import clientService from "../../../shared/services/client-service";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';

export default function RequestsPage() {
    return (
        <Box sx={{
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2),
        }}>
            <RequestTable />
        </Box>
    )
}

export interface RequestParams {
    from: string,
    to: string,
    searchText?: string,
    clientId?: number,
    pageSize: number,
    pageIndex: number,
    statusId?: number
}

export function RequestTable() {

    const [pageSize, setPageSize] = useState(5);
    const [pageIndex, setPageIndex] = useState(0);
    const [previousPageIndex, setPreviousPageIndex] = useState<Array<number>>([]);
    const [totalRequests, setTotalRequests] = useState(0);
    const [data, setData] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [statuses, setStatuses] = useState<Array<any>>([]);
    const [selectedStatus, setSelectedStatus] = useState<number>(100);

    //filter
    const [options, setOptions] = useState<Array<any>>([]);
    const [value, setValue] = useState<any>(null);
    const [from, setFrom] = useState<Dayjs | null>(dayjs().month(0));
    const [to, setTo] = useState<Dayjs | null>(dayjs);
    const [searchText, setSearchText] = useState<string>('');

    const navigate = useNavigate();

    const getRequests = () => {
        setLoading(true);
        const requestParams: RequestParams = {
            pageIndex,
            pageSize,
            from: from?.toISOString()!,
            to: to?.toISOString()!,
            searchText,
            clientId: value?.id!,
            statusId: selectedStatus == 100 ? undefined : selectedStatus
        };

        jobApplicationService.getJobApplications(requestParams)
            .then((response) => {
                setData(response.data.data!);
                setTotalRequests(response.data.totalCount);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }

    const downloadExcel = () => {
        setDownloadLoading(true);
        const requestParams: RequestParams = {
            pageIndex: 0,
            pageSize,
            from: from?.toISOString()!,
            to: to?.toISOString()!,
            searchText,
            clientId: value?.id!,
            statusId: selectedStatus == 100 ? undefined : selectedStatus
        };
        jobApplicationService.downloadExcel(requestParams)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Job_Applications_${dayjs().format('DD_MM_YYYY')}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setDownloadLoading(false);
            })
            .catch((error) => {
                setDownloadLoading(false);
                console.error(error);
            });
    }

    useEffect(() => {
        getClients();
        getStatuses();
    }, []);

    const getClients = () => {
        clientService.getAll({ pageIndex: 0, pageSize: 100, searchText: '' })
            .then((resp) => {
                setOptions(resp.data.data.filter((s: any) => s.role === "client"));
            })
            .catch(error => {
                console.log(error);
            })
    }

    const getStatuses = () => {
        jobApplicationService.getJobApplicationStatuses()
            .then(resp => {
                let data = resp.data;
                data.push({id: 100, name: 'All'});
                setStatuses(data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getRequests();
    }, [pageSize, pageIndex, selectedStatus, value]);

    const updateContactRequestStatus = (id: number, newStatus: number) => {
        let statusName = statuses.find(s => s.id === newStatus)!.name;
        let result = window.confirm(`Quieres cambiar el estado de la solicitud a ${statusName}?`);
        if (result) {
            let reviewerId = localStorage.getItem('userId');
            console.log('ReviewerId', reviewerId);
            jobApplicationService.updateJobApplicationStatus(id, newStatus, parseInt(reviewerId!))
                .then(resp => {
                    getRequests();
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    const nextPage = () => {
        setPreviousPageIndex([...previousPageIndex, pageIndex]);
        if (data.length > 0) {
            setPageIndex(data[data.length - 1].id);
        }
    }

    const previousPage = () => {
        const newPreviousPageIndex = [...previousPageIndex];
        let index = newPreviousPageIndex.pop();
        setPreviousPageIndex(newPreviousPageIndex);
        setPageIndex(index ?? 0);
    }

    return (
        <TableContainer component={Paper}>
            <Box sx={{
                backgroundColor: theme.palette.background.paper,
                paddingX: 2,
                paddingY: 1
            }}>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Autocomplete
                            value={value}
                            getOptionLabel={(option) => option.organizationName!}
                            onChange={(event: any, newValue: any) => {
                                setValue(newValue);
                                console.log(newValue)
                            }}
                            id="controllable-states-demo"
                            options={options}
                            renderInput={(params) => <TextField {...params} label="Buscar por cliente" />}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ marginBottom: 2, minWidth: 200 }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedStatus}
                                label="Stastus"
                                onChange={(event) => {
                                    setSelectedStatus(event.target.value as number)
                                }}
                            >
                                {statuses.map((status: any) => {
                                    return (<MenuItem value={status.id}>{status.name}</MenuItem>)
                                })}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={from}
                                onChange={(newValue) => setFrom(newValue)}
                                label="From" sx={{ marginX: 2 }} />
                            <DatePicker
                                value={to}
                                onChange={(newValue) => setTo(newValue)}
                                label="To" />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            value={searchText}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value)}
                            label="Filtro por Nombre/Apellido/Posición"
                        />
                    </Grid>
                    <Grid item xs={4} alignContent={'center'} justifyContent={'center'}>
                        <Button variant="contained" onClick={() => getRequests()}>Buscar</Button>
                        <Button variant="contained" color="secondary"
                            sx={{ marginLeft: 2 }}
                            onClick={() => downloadExcel()}>
                            {!downloadLoading
                                ? <DownloadIcon />
                                : <CircularProgress
                                    size={24}
                                    sx={{
                                        color: 'black',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />}

                            Excel
                        </Button>
                    </Grid>
                </Grid>


            </Box>
            {loading ?
                <Box justifyContent={'center'} alignContent={'center'} sx={{ display: 'flex' }}>
                    <CircularProgress size={100} />
                </Box> :

                <Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead sx={{
                            backgroundColor: theme.palette.background.default,
                        }}>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Reviewer</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Middle name</TableCell>
                                <TableCell>Last name</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Options</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data!.map((row) => (
                                <TableRow
                                    key={row.id}
                                    hover>
                                    <TableCell>
                                        {row!.applicant!.identification!}
                                    </TableCell>
                                    <TableCell>
                                        <FormControl sx={{ marginBottom: 2, marginTop: 1, minWidth: 200 }}>
                                            <Select
                                                value={row.statusId}
                                                label="Status"
                                                onChange={(event) => {
                                                    updateContactRequestStatus(row.id, event.target.value as number);
                                                }}>
                                                {statuses.map((status: any) => {
                                                    return (<MenuItem value={status.id}>{status.name}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>{row.reviewerId != null ? row!.reviewer!.name! : ''}</TableCell>
                                    <TableCell>{row!.applicant!.name}</TableCell>
                                    <TableCell>{row!.applicant!.middleName}</TableCell>
                                    <TableCell>{row!.applicant!.lastName}</TableCell>
                                    <TableCell>{row.jobOffer.position}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => navigate(`/dashboard/details/${row.id}`)}
                                            title="Open Details"
                                            aria-label="Open Details"
                                            color="primary">
                                            <VisibilityIcon></VisibilityIcon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            }
            <Grid
                container
                sx={{
                    padding: theme.spacing(2),
                }}>
                <Grid item md={6}>
                    <Typography fontWeight={"bold"} fontSize={14} variant="subtitle1" margin={2}>Total Requests: {totalRequests}</Typography>
                </Grid>
                <Grid item md={6} sx={{
                    display: 'flex',
                    justifyContent: 'end',
                }}>
                    <span style={{
                        margin: 5,
                        padding: 10,
                    }}>Page Size:</span>
                    <Select
                        variant="standard"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={pageSize}
                        label="Page Size"
                        onChange={(e) => setPageSize(e.target.value as number)}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                    <Button sx={{ marginLeft: 2 }} onClick={previousPage}>Previous</Button>
                    <Button sx={{ marginLeft: 2 }} onClick={nextPage}>Next</Button>
                </Grid>
            </Grid>
        </TableContainer>);
}