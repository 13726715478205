import { Box, Button, CircularProgress, Grid, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import theme from "../../../theme";
import { useEffect, useState } from "react";
import clientService from "../../../shared/services/client-service";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { useNavigate } from "react-router-dom";

export default function Clients() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<any>>([]);
    const [pageSize, setPageSize] = useState(5);
    const [pageIndex, setPageIndex] = useState(0);
    const [previousPageIndex, setPreviousPageIndex] = useState<Array<number>>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        getClient();
    }, [pageIndex, pageIndex]);

    const getClient = () => {
        setLoading(true);
        clientService.getAll({
            pageIndex,
            pageSize,
            searchText
        })
            .then(resp => {
                setData(resp.data.data.filter((s: any) => s.role === "client"));
                setTotalCount(resp.data.totalCount);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    };

    const nextPage = () => {
        setPreviousPageIndex([...previousPageIndex, pageIndex]);
        if (data.length > 0) {
            setPageIndex(data[data.length - 1].id);
        }
    }

    const previousPage = () => {
        const newPreviousPageIndex = [...previousPageIndex];
        let index = newPreviousPageIndex.pop();
        setPreviousPageIndex(newPreviousPageIndex);
        setPageIndex(index ?? 0);
    }

    return (
        <>
            <Box sx={{
                backgroundColor: theme.palette.background.paper,
                paddingX: 2,
                paddingY: 1
            }}>

                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    Clients Page
                </Typography>

                <TableContainer>

                    <Grid container>
                        <Grid item alignContent={'center'} justifyContent={'center'}>
                            <TextField
                                sx={{ minWidth: 600, marginBottom: 1, marginTop: 1, marginRight: 1 }}
                                label="Buscar por nombre de cliente"
                                onChange={(event: any) => { setSearchText(event.target.value); }} />
                            <Button sx={{ marginTop: 2 }}
                                title="Buscar"
                                variant="contained"
                                onClick={() => getClient()}>Buscar</Button>
                        </Grid>
                    </Grid>
                    {loading
                        ? <Box justifyContent={'center'} alignContent={'center'} sx={{ display: 'flex' }}>
                            <CircularProgress size={100} />
                        </Box>
                        : <Box>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{
                                    backgroundColor: theme.palette.background.default,
                                }}>
                                    <TableRow>
                                        <TableCell>Organization Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone Number</TableCell>
                                        <TableCell>Country</TableCell>
                                        <TableCell>State</TableCell>
                                        <TableCell>City</TableCell>
                                        <TableCell>Options</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data!.map((row: any) => (
                                        <TableRow
                                            key={row.id}
                                            hover>
                                            <TableCell>{row.organizationName}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.phoneNumber}</TableCell>
                                            <TableCell>{row.country}</TableCell>
                                            <TableCell>{row.state}</TableCell>
                                            <TableCell>{row.city}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => navigate(`/dashboard/jobOffers/${row.id}/${row.organizationName}`)}
                                                    title="Open Details"
                                                    aria-label="Open Details"
                                                    color="primary">
                                                    <WorkHistoryIcon></WorkHistoryIcon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>}
                    <Grid
                        container
                        sx={{
                            padding: theme.spacing(2),
                        }}>
                        <Grid item md={6}>
                            <Typography fontWeight={"bold"} fontSize={14} variant="subtitle1" margin={2}>Total Requests: {totalCount}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{
                            display: 'flex',
                            justifyContent: 'end',
                        }}>
                            <span style={{
                                margin: 5,
                                padding: 10,
                            }}>Page Size:</span>
                            <Select
                                variant="standard"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={pageSize}
                                label="Page Size"
                                onChange={(e) => setPageSize(e.target.value as number)}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                            <Button sx={{ marginLeft: 2 }} onClick={previousPage}>Previous</Button>
                            <Button sx={{ marginLeft: 2 }} onClick={nextPage}>Next</Button>
                        </Grid>
                    </Grid>
                </TableContainer>
            </Box>

        </>
    );
}