import { Alert, Box, Button, CircularProgress, Fab, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import theme from "../../../theme";
import { useEffect, useState } from "react";
import clientService from "../../../shared/services/client-service";
import GroupAddIcon from '@mui/icons-material/GroupAdd';

export interface GetContactRequestParams {
    pageSize: number,
    pageIndex: number,
    status: number
}

export default function ContactRequestsPage() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<any>>([]);
    const [statuses, setStatuses] = useState<Array<any>>([]);
    const [selectedStatus, setSelectedStatus] = useState<number>(0);
    const [pageSize, setPageSize] = useState(5);
    const [pageIndex, setPageIndex] = useState(0);
    const [previousPageIndex, setPreviousPageIndex] = useState<Array<number>>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        getStatuses();
        getData();
    }, []);

    const getData = () => {
        setLoading(true);
        let params: GetContactRequestParams = {
            pageIndex,
            pageSize,
            status: selectedStatus
        };

        clientService.getAllContactRequests(params)
            .then(resp => {
                setData(resp.data.data);
                setTotalCount(resp.data.totalCount);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    const createClientFromContactRequest = (requestId: number) => {
        setLoading(true);
        clientService.createClientFromContactRequest(requestId)
            .then(resp => {
                getData();
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
                setErrorMessage(error!.response!.data);
                setTimeout(() => {
                    setErrorMessage("");
                }, 3000);
            });
    }

    const updateContactRequestStatus = (id: number, newStatus: number) => {
        let statusName = statuses.find(s => s.id === newStatus)!.name;
        let result = window.confirm(`Quieres cambiar el estado de la solicitud a ${statusName}?`);
        if (result) {
            clientService.updateContactRequestStatus(id, newStatus)
                .then(resp => {
                    getData();
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    const getStatuses = () => {
        clientService.getAllContactRequestStatuses()
            .then(resp => {
                setStatuses(resp.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getData();
    }, [pageSize, pageIndex, selectedStatus])

    const nextPage = () => {
        setPreviousPageIndex([...previousPageIndex, pageIndex]);
        if (data.length > 0) {
            setPageIndex(data[data.length - 1].id);
        }
    }

    const previousPage = () => {
        const newPreviousPageIndex = [...previousPageIndex];
        let index = newPreviousPageIndex.pop();
        setPreviousPageIndex(newPreviousPageIndex);
        setPageIndex(index ?? 0);
    }

    return (
        <>
            {errorMessage.length > 0
                && <Alert severity="error">{errorMessage}</Alert>}
            <Box sx={{
                backgroundColor: theme.palette.background.paper,
                paddingX: 2,
                paddingY: 1
            }}>

                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    Contact Requests Page
                </Typography>

                <TableContainer>
                    {loading
                        ? <Box justifyContent={'center'} alignContent={'center'} sx={{ display: 'flex' }}>
                            <CircularProgress size={100} />
                        </Box>
                        : <Box>
                            <FormControl sx={{ marginBottom: 2, marginTop: 1, minWidth: 200 }}>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedStatus}
                                    label="Stastus"
                                    onChange={(event) => {
                                        setSelectedStatus(event.target.value as number)
                                    }}
                                >
                                    {statuses.map((status: any) => {
                                        return (<MenuItem value={status.id}>{status.name}</MenuItem>)
                                    })}

                                </Select>
                            </FormControl>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{
                                    backgroundColor: theme.palette.background.default,
                                }}>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Contact name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Country</TableCell>
                                        <TableCell>Phone Number</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Options</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data!.map((row: any) => (
                                        <TableRow
                                            key={row.id}
                                            hover>
                                            <TableCell>{row.companyName}</TableCell>
                                            <TableCell>{row.contactName}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.country}</TableCell>
                                            <TableCell>{row.phoneNumber}</TableCell>
                                            <TableCell>
                                                <FormControl sx={{ marginBottom: 2, marginTop: 1, minWidth: 200 }}>
                                                    <Select
                                                        value={row.contactRequestStatusId}
                                                        label="Status"
                                                        onChange={(event) => {
                                                            updateContactRequestStatus(row.id, event.target.value as number);
                                                        }}>
                                                        {statuses.map((status: any) => {
                                                            return (<MenuItem value={status.id}>{status.name}</MenuItem>)
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    aria-label="Create Client"
                                                    title="Create Client"
                                                    color="primary"
                                                    onClick={() => {
                                                        createClientFromContactRequest(row.id);
                                                    }}>
                                                    <GroupAddIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>}
                    <Grid
                        container
                        sx={{
                            padding: theme.spacing(2),
                        }}>
                        <Grid item md={6}>
                            <Typography fontWeight={"bold"} fontSize={14} variant="subtitle1" margin={2}>Total Requests: {totalCount}</Typography>
                        </Grid>
                        <Grid item md={6} sx={{
                            display: 'flex',
                            justifyContent: 'end',
                        }}>
                            <span style={{
                                margin: 5,
                                padding: 10,
                            }}>Page Size:</span>
                            <Select
                                variant="standard"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={pageSize}
                                label="Page Size"
                                onChange={(e) => setPageSize(e.target.value as number)}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                            <Button sx={{ marginLeft: 2 }} onClick={previousPage}>Previous</Button>
                            <Button sx={{ marginLeft: 2 }} onClick={nextPage}>Next</Button>
                        </Grid>
                    </Grid>
                </TableContainer>
            </Box>

        </>
    )
}