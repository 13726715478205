import { useTranslation } from "react-i18next";
import LandingSection from "../../../shared/components/landing-section";
import NavBar from "../../../shared/components/nav-bar";
import theme from "../../../theme";
import Footer from "../footer";
import ContactUsPage from "./contact-us";
import ClientAboutUs from "./about-us";
import WhatWeDo from "./what-we-do";

export default function ClientHome() {
    const { t } = useTranslation();
    return (
        <>
            <NavBar showLinks={false}>
                <LandingSection
                    id={"aboutUs"}
                    style={{
                        width: '100%',
                        backgroundColor: theme.palette.primary.main
                    }}>
                    <ClientAboutUs />
                </LandingSection>
                <LandingSection
                    id={"weDo"}
                    style={{
                        width: '100%',
                        backgroundColor: 'white'
                    }}>
                    <WhatWeDo />
                </LandingSection>
                <LandingSection
                    id={t("nav.home")}
                    style={{
                        width: '100%',
                        backgroundColor: "#edecff"
                    }}>
                    <ContactUsPage />
                </LandingSection>
                <Footer />

            </NavBar>
        </>);
}