import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Confirmation() {
    return (
        <Container>
            <Box
                borderRadius={2}
                bgcolor={"Background"}
                boxShadow={1}
                sx={{ padding: 2, marginTop:5, height: 'auto', marginBottom: 5 }}>
                <Typography variant="h3">Thank you for your interest, We have sent you a confirmation email.</Typography>
                <Link to={"/"}>
                    <Button>Back Home</Button>
                </Link>
            </Box>
        </Container>
    );
}